.quantitySelector {
  position: relative;
}

/* Remove any default appearance */
.slider {
  width: 100%;
  height: 10px; /* Used to set overall height; actual track styled separately */
  display: block;
  -webkit-appearance: none;
  appearance: none;
  background: transparent; /* Set to transparent because we style the track separately */
  outline: none;
}

/* Chrome track styling */
.slider::-webkit-slider-runnable-track {
  height: 10px;
  background: linear-gradient(
    to right,
    var(--color-primary-1) 0%,
    var(--color-primary-1) var(--slider-percentage, 50%),
    var(--color-blue-050) var(--slider-percentage, 50%),
    var(--color-blue-050) 100%
  );
  border-radius: 10px;
}

/* Firefox track styling */
.slider::-moz-range-track {
  height: 10px;
  background: linear-gradient(
    to right,
    var(--color-primary-1) 0%,
    var(--color-primary-1) var(--slider-percentage, 50%),
    var(--color-blue-050) var(--slider-percentage, 50%),
    var(--color-blue-050) 100%
  );
  border-radius: 10px;
}

/* Focus styles */
.slider:focus {
  outline: none;
}

.slider:focus-visible {
  outline: 2px solid var(--color-primary-1);
  outline-offset: 2px;
}

/* Thumb styling for Chrome */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  border: 1px solid var(--color-blue-200);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Use margin-top to vertically center the thumb on the track */
  margin-top: -7px;
  position: relative;
}

/* Thumb styling for Firefox */
.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  border: 1px solid var(--color-blue-200);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Tick marks */
.ticks {
  display: flex;
  justify-content: space-between;
  padding: 0 3px;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: 4px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.tick {
  width: 4px;
  height: 4px;
  background: var(--color-blue-200);
  border-radius: 50%;
  position: relative;
}

.tick[data-active="true"] {
  background: #00a786; /* teal-500 */
}

.tick[data-current="true"] {
  background: transparent;
}

/* Visually hide labels but keep them accessible for screen readers */
.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
