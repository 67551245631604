.planInfoBox {
  background: #ffffff;
  box-shadow:
    0 3px 2px -2px rgba(0, 0, 0, 0.12),
    0 4px 6px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 1.77rem;
  width: 100%;
}

.planHeader {
  text-align: left;
  display: flex;
  align-items: center;
}

.planHeader img {
  width: 64px;
  margin-right: 16px;
  flex-shrink: 0;
}

.planTitleWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.75rem;
  row-gap: 0.1rem;
}

.planTitle {
  margin: 0;
  font-size: 1.667rem;
  line-height: 1.3;
}

.planTitle_small {
  font-size: 1.334rem;
}

.mostPopular {
  display: block;
  padding: 0.3em 0.75em;
  font-size: 0.875rem;
  background-image: url(./line-most-popular.svg);
  background-size: cover;
  white-space: nowrap;
  position: relative;
  bottom: -0.1em;
}

.planPrice {
  margin-top: 1.25rem;
  display: flex;
  gap: 0.25rem;
  align-items: flex-end;
}

.planPricePrice {
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  font-weight: bold;
  line-height: 1;
}

.planPricePrice_text {
  letter-spacing: 0;
}

.planPriceInfo {
  font-size: 0.7778rem;
  color: var(--color-blue-500);
  margin-left: 6px;
  line-height: 1.4;
  position: relative;
  top: -0.2em;
}

.planAdditionalInfo {
  font-size: 0.875rem;
  color: var(--color-blue-600);
  margin-top: 1rem;
}

.planAdditionalInfo:after {
  content: "";
  border-top: 1px solid var(--color-blue-200);
  padding-top: 24px;
  margin-top: 24px;
  display: block;
}

.planSelectQuantity {
  margin-top: calc(1rem + 2px);
}

.planSelectQuantity + .startFreeTrialButton {
  margin-top: calc(2rem + 5px);
}

.planSelectQuantity select {
  width: 100%;
}

.includedTesters {
  margin-top: 1rem;
  font-size: var(--font-size-small);
}

.includedTestersPeriod {
  color: var(--color-blue-400);
}

.startFreeTrialButton {
  padding: 0.875rem 0;
  margin-top: 1.25rem;
  white-space: nowrap;
}

.featureList {
  --line-and-icon-height: var(--font-size-small);
  margin: 0;
  margin-top: 2rem;
  text-align: left;
  font-size: var(--font-size-small);
  line-height: var(--line-and-icon-height);
  list-style: none;
  padding: 0;
}

.featureList li + li {
  margin-top: 12px;
}

.featureList li {
  background-repeat: no-repeat;
  background-size: var(--line-and-icon-height);
  background-image: url(./featureListIconGreen.svg);
  padding-left: 32px;
}

.featureList li[data-feature-included="false"] {
  color: var(--color-blue-500);
  background-image: url(./featureListIconGray.svg);
}

.paygSummaryRow {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  font-size: var(--font-size-small);
  gap: 0.5rem;
  color: var(--color-blue-100);
  letter-spacing: 0.01em;
}

.paygSummaryRow + .paygSummaryRow {
  border-top: 1px solid var(--color-blue-500);
}

.paygSummaryRow_total {
  font-weight: bold;
}

.planInfoFooter {
  margin-top: 2rem;
  padding-bottom: 1rem;
  text-align: center;
}

@media (min-width: 975px) {
  .featureList {
    --line-and-icon-height: 22px;
  }

  .featureList li + li {
    margin-top: 18px;
  }
}

.outdatedInfo {
  font-size: var(--font-size-small);
  color: var(--color-blue-500);
  background: var(--color-blue-050);
  padding: 0.875rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
}

.planInfoBox_outdated {
  position: relative;
  overflow: hidden;
}

.planInfoBox_outdated:before {
  content: "Legacy plan";
  display: block;
  width: 13em;
  background: var(--color-blue-050);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  padding: 0.5em;
  transform: rotate(45deg);
  position: absolute;
  right: -3.6em;
  top: 2em;
}

.currentPrice {
  font-size: var(--font-size-small);
  color: var(--color-blue-600);
}

@media (min-width: 975px) {
  .planInfoBox_outdated {
    grid-column: span 3;
    display: flex;
    column-gap: 5.54rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .planInfoBox_outdated > * {
    width: calc((100% - (2 * 2rem + 4 * 1.77rem)) / 3);
    border-top: 0;
    box-sizing: border-box;
  }

  .outdatedInfo {
    margin-top: 0;
    order: 100;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
