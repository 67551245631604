.container {
  padding: var(--padding-card);
  padding-top: 2.5rem;
  position: relative;
}

.container_loading {
  opacity: 0.5;
  pointer-events: none;
}

.closeButton {
  position: absolute;
  top: var(--padding-card);
  right: var(--padding-card);
  background: none;
  border: none;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  transition: background-color 0.25s;
}

.closeButton:hover {
  cursor: pointer;
  background: var(--color-blue-100);
}

.errorMessage {
  margin-top: 2rem;
}

.heading {
  font-size: 1.5rem;
  margin: 0;
  color: var(--color-blue-900);
}

.genericPayment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  border-top: 1px solid var(--color-blue-200);
  border-bottom: 1px solid var(--color-blue-200);
}

.genericPaymentTotalAndBreakdown {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.genericPaymentProductName,
.genericPaymentPlanName,
.genericPaymentTotal {
  color: var(--color-blue-900);
  font-size: 1.188rem;
}

.genericPaymentTotal {
  font-weight: bold;
}

.genericPaymentBreakdown,
.paymentDescription {
  font-size: 0.938rem;
  color: var(--color-blue-700);
}

.paymentDescription {
  margin-bottom: 0;
}

.paymentDescription strong {
  color: var(--color-blue-900);
  font-weight: bold;
}

.paymentDescription span {
  color: var(--color-blue-400);
}

.footer {
  margin-top: 4rem;
}

.paymentForm {
  margin-top: 3rem;
}

.legalNewAgreement,
.legalExistingAgreement,
.legalCheckbox {
  font-size: 0.938rem;
}

.legalNewAgreement,
.legalExistingAgreement,
.legalCheckbox,
.legalCheckbox a,
.legalExistingAgreement a,
.legalNewAgreement a {
  color: var(--color-blue-600);
}

.legalNewAgreement {
  margin: 0;
}

.legalExistingAgreement {
  margin: 2rem 0.5rem 0 0.5rem;
  text-align: center;
}

.legalExistingAgreement a:hover,
.legalNewAgreement a:hover {
  color: var(--color-blue-900);
}

.legalCheckboxFormField {
  margin-top: 2.25rem;
}

.legalFormFieldError {
  margin-left: calc(1.25em + 0.15rem + 0.5em + 4px);
}

.subscriptionPayment {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2.5rem;
}

.subscriptionPaymentButton {
  font-size: 1.25rem;
  color: var(--color-blue-600);
  border: 2px solid var(--color-blue-200);
  border-radius: var(--border-radius-button);
  background: none;
  width: 100%;
  padding: 1.125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-weight: bold;
  transition: border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  user-select: auto;
}

.subscriptionPaymentButton_active {
  cursor: default;
  border-color: var(--color-blue-900);
}

.subscriptionPaymentButton:hover {
  transition: none;
  border-color: var(--color-blue-400);
}

.subscriptionPaymentButton_active:hover {
  border-color: inherit;
}

.subscriptionPaymentCost {
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: medium;
  color: var(--color-blue-400);
  transition: color 0.25s;
}

.subscriptionPaymentCost_active {
  color: var(--color-blue-700);
}

.subscriptionPaymentDiscount {
  font-size: 0.75rem; /* 12px - smallest readable size */
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background: var(--color-primary-1);
  border-radius: 10rem;
  padding: 0.125rem 0.35rem;
  font-weight: bold;
}

.subscriptionPaymentVatInfo {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  text-align: left;
  color: var(--color-blue-400);
}

@media (min-width: 500px) {
  .container {
    padding: 2.5rem;
  }

  .subscriptionPaymentDiscount {
    top: 0.875rem;
    right: 0.875rem;
    padding: 0.35rem 0.75rem;
  }
}
