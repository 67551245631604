.exportPlansPromo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-card);
  column-gap: 1.5rem;
  row-gap: 1rem;
}

.image {
  width: 150px;
  max-width: 100%;
  min-width: 0;
}

.content {
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  row-gap: 1rem;
}

.content > div {
  flex-basis: 20rem;
  flex-grow: 1;
}

.heading {
  font-size: 1.5rem;
  margin: 0;
}

.heading + p {
  margin: 0.5rem 0 0 0;
  color: var(--color-blue-600);
}

@media (min-width: 35rem) {
  .exportPlansPromo {
    flex-direction: row;
  }
  .image {
    width: auto;
    max-width: 150px;
    min-width: 0;
  }
}
